window.addEventListener('heyflow-screen-view', (event) => {

console.log('heyflow screen view:', event.detail);
if(event.detail.screenName === 'hsntsn'){
    
    

    
        
        
        let hsntsn_hsn = document.querySelector('[data-variable="hsntsn_HSN"]');
        let hsntsn_tsn = document.querySelector('[data-variable="hsntsn_TSN"]');
        console.log('hsntsn_hsn>>>',hsntsn_hsn.value);
        console.log('hsntsn_tsn>>>',hsntsn_tsn.value);
        // empty the liste secreen modell values
        var liste_modell = document.querySelector('[data-variable="liste_modell"]');
        var liste_marke = document.querySelector('[data-variable="liste_marke"]');
        liste_modell.innerHTML = '';
        liste_marke.innerHTML = ''; 
        
        console.log(liste_modell);
        
        if( hsntsn_hsn.value != '' && hsntsn_tsn.value != ''){
           const res = fetch('https://computing-power-742--automobile.sandbox.my.salesforce-sites.com/Heyflow/services/apexrest/fetchVehecle/'+hsntsn_hsn.value+'&'+hsntsn_tsn.value)
                      .then(response =>{
                          if(response.ok){
                              return response.json();
                          }
                      }
                      )
                      .then(data =>{
                             console.log('Data>>>',data);
                             
                             let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
                             let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
                             hsntsn_marke.readOnly = true;
                             hsntsn_modell.readOnly = true;
                             console.log('hsntsn_marke>>>',hsntsn_marke.value);
                             console.log('hsntsn_modell>>>',hsntsn_modell.value);
                             // Labels
                             let brandslabel = document.querySelector('label[for="input-2fde0836"]');
                             let carslabel = document.querySelector('label[for="input-34f090da"]');
                             
                             hsntsn_marke.style.visibility='visible';
                             hsntsn_modell.style.visibility='visible';
                             
                             brandslabel.style.visibility = 'visible';
                             carslabel.style.visibility = 'visible';
                             hsntsn_marke.value = data.Fabrikmarke__c;
                             hsntsn_modell.value = data.Handelsname__c;
                      }
                      );
        }
        
        
   
    


//Hide the input and labels

 let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
 let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
 hsntsn_marke.readOnly = true;
 hsntsn_modell.readOnly = true;                            
 console.log('hsntsn_marke>>>',hsntsn_marke.value);
 console.log('hsntsn_modell>>>',hsntsn_modell.value);

let brandslabel = document.querySelector('label[for="input-2fde0836"]');
let carslabel = document.querySelector('label[for="input-34f090da"]');
brandslabel.style.visibility = 'hidden';
carslabel.style.visibility = 'hidden';
hsntsn_marke.style.visibility = 'hidden';
hsntsn_modell.style.visibility = 'hidden';

console.log('heyflow screen view: if Condition', event.detail);
window.addEventListener('heyflow-change', (event) => {
console.log('heyflow change fieldsSimples:', event.detail.fieldsSimple);
console.log('heyflow change:', event.detail);
if(event.detail.fieldsSimple.HSN != undefined && event.detail.fieldsSimple.TSN != undefined){
console.log('event.detail.fieldsSimple.HSN',event.detail.fieldsSimple.HSN);
console.log('event.detail.fieldsSimple.TSN',event.detail.fieldsSimple.TSN);
let varhsn = event.detail.fieldsSimple.HSN;
let vartsn = event.detail.fieldsSimple.TSN;
let hsntsn = varhsn +'&'+vartsn;
const respone = fetch('https://computing-power-742--automobile.sandbox.my.salesforce-sites.com/Heyflow/services/apexrest/fetchVehecle/'+hsntsn) // api for the get request
.then(response =>{
 if(!response.ok){
     
     
     
     let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
     let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
     // Labels
     let brandslabel = document.querySelector('label[for="input-2fde0836"]');
     let carslabel = document.querySelector('label[for="input-34f090da"]');
     hsntsn_marke.style.visibility='hidden';
     hsntsn_modell.style.visibility='hidden';
     
     brandslabel.style.visibility = 'hidden';
     carslabel.style.visibility = 'hidden';
     
     
     
     throw new Error(' response was not ok');
 }
 return response.json();
 
})
.then(data =>{

 console.log(data.Name);
 
 
 let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
 let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
 
 // Labels
 let brandslabel = document.querySelector('label[for="input-2fde0836"]');
 let carslabel = document.querySelector('label[for="input-34f090da"]');
 
 hsntsn_marke.style.visibility='visible';
 hsntsn_modell.style.visibility='visible';
 
 brandslabel.style.visibility = 'visible';
 carslabel.style.visibility = 'visible';
 hsntsn_marke.value = data.Fabrikmarke__c;
 hsntsn_modell.value = data.Handelsname__c;
 
} );



/*console.log('jsonbody',respone)
let el_down = document.getElementById('input-49aa2f34');
console.log(el_down.value);
el_down.value = event.detail.fieldsSimple.HSN + ' ' + event.detail.fieldsSimple.TSN;
event.detail.fieldsSimple.Output = el_down.value;
console.log('heyflow change:', event.detail.fieldsSimple);*/
}
});
}








if(event.detail.screenName === 'liste'){
    
    // empty the modelllist 
    
   
         
   
   // empty the values of hsntsn secreen inputs values
   let hsntsn_hsn = document.querySelector('[data-variable="hsntsn_HSN"]');
   let hsntsn_tsn = document.querySelector('[data-variable="hsntsn_TSN"]');
   let hsntsn_marke = document.querySelector('[data-variable="hsntsn_marke"]');
   let hsntsn_modell = document.querySelector('[data-variable="hsntsn_modell"]');
   
   hsntsn_hsn.value = '';
   hsntsn_tsn.value = '';
   hsntsn_marke.value = '';
   hsntsn_modell.value = '';
 
   //data-variable="liste_marke"
   var liste_marke = document.querySelector('[data-variable="liste_marke"]');
    
fetch("https://computing-power-742--automobile.sandbox.my.salesforce-sites.com/Heyflow/services/apexrest/fetchmaker/")
.then(response => response.json())
.then(data =>{
console.log(data.length)

data.forEach(function(d) {
    let option = document.createElement('option');
    option.value = d;
    option.textContent = d;
    liste_marke.appendChild(option);
});
 


} )
.catch(error => console.error(error));
console.log('heyflow screen view',event.detail);

// First Endpoint Hit End




// 2nd Endpoint Hit start

    let carslabel = document.querySelector('[id="id-620ded9c"]');
    
    var liste_marke = document.querySelector('[data-variable="liste_marke"]');
    
   if(!liste_marke.hasListener){
    liste_marke.addEventListener('change', () => {
        
        console.log('event.detail.screenName>>>>',event.detail.screenName);
        
        
        var liste_marke_value = document.querySelector('[data-variable="liste_marke"]');
     console.log(liste_marke_value.value);
     if(liste_marke_value.value == 'None'){
         
         //data-variable="liste_modell"
         
         var liste_modell = document.querySelector('[data-variable="liste_modell"]');
         for ( var i = newmodell.length - 1; i >= 0 ; i--) {
               liste_modell.options[i] = null;
             }
         }
      if(liste_marke_value.value != 'None'){
          
          var liste_modell = document.querySelector('[data-variable="liste_modell"]');
          for (i = liste_modell.length - 1; i >= 0 ; i--) {
     
               liste_modell.options[i] = null;
    
             }
           console.log(encodeURIComponent(liste_marke_value.value));
	       let encodedName = encodeURIComponent(liste_marke_value.value);
	       fetch("https://computing-power-742--automobile.sandbox.my.salesforce-sites.com/Heyflow/services/apexrest/fetchModell/?modell=" + encodedName)
	       .then(response => response.json())
	       .then(data => {
		    console.log('data',data);
		    var liste_modell = document.querySelector('[data-variable="liste_modell"]');
		    liste_modell.innerHTML = '';
		    data.forEach(function(d) {
            let option = document.createElement('option');
            option.value = d;
            option.textContent = d;
            liste_modell.appendChild(option);
            });

		 })
	 .catch(error => console.error(error));
     
          
      }
        
    });
       
   }

window.addEventListener('heyflow-change', (event) => {
    
     console.log('heyflow change fieldsSimples:', event.detail.fieldsSimple);
     console.log('heyflow change fields:', event.detail.fields);
     
     console.log('heyflow change:', event.detail);
     
    
    
});





}





});



window.addEventListener('heyflow-submit', (event) => {
// console.log('heyflow submit:', event.detail);
});



/*window.addEventListener('heyflow-change', (event) => {

});*/